import { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'
import type { TabItemIdName } from '@/components/challenges/MyChallengesPage/tabMenu/HorizontalTabMenuComponent.vue'
import type DateService from '@/services/Date.service'
import { Challenge } from '@/store/FiftyTypes'
import type { IValidateChallengePayload } from '@/store/modules/ChallengesStore'
import { DISPATCH_CHALLENGE_VALIDATE, GET_CURRENT_SUGGESTED_CHALLENGES } from '@/store/modules/ChallengesStore'
import type { Router } from 'vue-router'
import type { Store } from 'vuex'
import { SHOW_NPS } from '@/components/app/nps/NpsComponentUtils'
import { PAGE_CONGRATULATIONS_FEEDBACK } from '@/router/routes'
import emitter from '@/utils/emitter'
import { State } from '@/store/store'

export default class ChallengeHelper {
  public getPillarName(challenge: Challenge | null | undefined): string {
    return challenge?.programAction?.pillar?.pillarTranslation?.name ?? ''
  }

  public static challengeDtoToChallenge(dtoChallenges: FiftyApiClients.ChallengeDto[]): Challenge[] {
    const challenges: Challenge[] = []
    for (let i = 0; i < dtoChallenges.length; i++) {
      const dtoChallenge: FiftyApiClients.ChallengeDto = dtoChallenges[i]
      for (let j = 0; j < dtoChallenge.programActions!.length; j++) {
        challenges.push(new Challenge(dtoChallenge, dtoChallenge.programActions![j]))
      }
    }

    return challenges
  }

  public isOnTheGoDuration(duration: FiftyApiClients.BaseActionDurationDto | null | undefined): boolean {
    return duration === FiftyApiClients.BaseActionDurationDto.OnTheGo
  }

  /**
   * Used to set up tab navigation for each program in challenges list pages
   * @param user
   */
  public tabItems(user: FiftyApiClients.UserDto, $dateService: DateService): TabItemIdName[] {
    const formatCourseName = (userCampaign: FiftyApiClients.UserCampaignDto) => {
      if (!userCampaign.campaignName) {
        return ''
      }
      const courseBeginFormatted = ` (${$dateService.format(userCampaign.startAt, 'MMM YYYY').toLowerCase()})`
      return userCampaign.campaignName + courseBeginFormatted
    }
    const allCourses: TabItemIdName[] = user
      .userCampaigns!.filter((userCampaign) => userCampaign.programId !== null && userCampaign.programId !== '')
      .map((userCampaign) => ({ id: userCampaign.programId!, name: formatCourseName(userCampaign) }))
    return allCourses.filter(
      (course, index: number, self) =>
        index === self.findIndex((courseNameId) => courseNameId.id === course.id && courseNameId.name === course.name),
    )
  }

  public currentCampaignTabName(
    lastActiveCampaignId: string | null,
    lastActiveCampaignName: string | null,
    tabName: any,
  ): Map<string, string> | null {
    if (!lastActiveCampaignId || !lastActiveCampaignName) {
      return null
    }
    const specificTabNames = new Map()
    const currentCampaignNameTab = lastActiveCampaignName + tabName
    specificTabNames.set(lastActiveCampaignId, currentCampaignNameTab)
    return specificTabNames
  }

  /**
   * Return true if this challenge has to be shown as in progress
   * @param challenge
   * @param evenIfNoValidatedYet
   */
  public showIsCurrentlyInProgress(challenge: Challenge | null | undefined, evenIfNoValidatedYet = false): boolean {
    if (!challenge?.usage) {
      return false
    }
    return this.isAccepted(challenge) && (evenIfNoValidatedYet || challenge.usage.validatedCount > 0)
  }

  public isAccepted(challenge: Challenge | null | undefined): boolean {
    return challenge?.usage?.status === FiftyApiClients.ChallengeUsageStatusDto.Accepted
  }

  public isAwaitingAcceptation(challenge: Challenge | null | undefined): boolean {
    return challenge?.usage?.status === FiftyApiClients.ChallengeUsageStatusDto.WaitingAcceptation
  }

  public isClosed(challenge: Challenge | null | undefined): boolean {
    return challenge?.usage?.status === FiftyApiClients.ChallengeUsageStatusDto.Closed
  }

  public currentSessionChallengeChoiceAccepted(challenge: Challenge | null | undefined): boolean {
    return challenge?.usage?.choiceUsage?.choiceStatus === FiftyApiClients.ChallengeChoiceUsageStatusDto.Accepted
  }

  public isCompleted(challenge: Challenge | null | undefined): boolean {
    return this.isValidated(challenge) || this.isClosed(challenge)
  }

  public isValidated(challenge: Challenge | null | undefined): boolean {
    return challenge?.usage?.status === FiftyApiClients.ChallengeUsageStatusDto.Validated
  }

  public isDoneForGood(challenge: Challenge | null | undefined) {
    return this.isValidated(challenge) && !challenge?.data?.isRepeatable
  }

  public canBeEdited(challenge: Challenge | null | undefined) {
    return challenge?.data.isCustomChallenge && challenge.data.isCustomOwnedChallenge
  }

  public validatedAndNoMoreActionsPossible(challenge: Challenge | null | undefined) {
    return this.isDoneForGood(challenge)
  }

  public canBeRedone(challenge: Challenge | null | undefined) {
    return challenge && this.isCompleted(challenge) && !this.validatedAndNoMoreActionsPossible(challenge)
  }

  public static async validateChallenge(store: Store<any>, payload: IValidateChallengePayload) {
    payload.onBackEndChallengeValidated = ChallengeHelper.goToCongratulationsPage

    const dispatchResponse: FiftyApiClients.ValidateResponse | null | undefined = await store.dispatch(
      DISPATCH_CHALLENGE_VALIDATE,
      payload,
    )

    if (dispatchResponse?.shouldDisplayNps ?? false) {
      emitter.$emit(SHOW_NPS)
    }
  }

  public static goToCongratulationsPage(shortChallengeId: string, router: Router) {
    if (!router) {
      return
    }

    const routeToGO = {
      name: PAGE_CONGRATULATIONS_FEEDBACK,
      params: {
        shortChallengeId,
      },
      query: {
        from: router.currentRoute.value.path,
      },
    }

    return router.push(routeToGO)
  }

  public static canSelectNewChallenge(user: FiftyApiClients.UserDto, store: Store<State>): boolean {
    const suggested = store.getters[GET_CURRENT_SUGGESTED_CHALLENGES] as Challenge[]

    return (
      !!user.currentUserCampaign &&
      suggested?.filter((x) => x.usage?.status === FiftyApiClients.ChallengeUsageStatusDto.WaitingAcceptation).length >
        0
    )
  }
}
