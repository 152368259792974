import { ref } from 'vue'
import i18n from '@/i18n'
import dayjs from 'dayjs'
import * as Sentry from '@sentry/vue'
import OtaClient from '@crowdin/ota-client'
import localeData from 'dayjs/plugin/localeData'
import type { FiftyApiClients } from '@/store/services/apiClient/generated/fiftyApiClient'

dayjs.extend(localeData)

export enum SupportedLanguageDto {
  Fr = 0,
  En = 1,
  It = 4,
}
export type SupportedLanguage = {
  language: SupportedLanguageDto
  languageShort: string
  languageLongEnglish: string
  languageLongLocale: string
  languagePhoneIso2: string
  isMandatory: boolean
  label: string
  id: string
  flag?: string
}

export enum i18nLocale {
  Fr = 'fr',
  En = 'en',
  It = 'it',
  Custom = 'custom',
}

export const SUPPORTED_LOCALES: SupportedLanguage[] = [
  {
    id: SupportedLanguageDto.Fr.toString(),
    language: SupportedLanguageDto.Fr,
    languageShort: i18nLocale.Fr,
    languageLongEnglish: 'French',
    languageLongLocale: 'Français',
    languagePhoneIso2: 'FR',
    isMandatory: true,
    label: 'Français',
    flag: '/img/languagesFlags/fr.svg',
  },
  {
    id: SupportedLanguageDto.En.toString(),
    language: SupportedLanguageDto.En,
    languageShort: i18nLocale.En,
    languageLongEnglish: 'English',
    languageLongLocale: 'English',
    languagePhoneIso2: 'GB',
    isMandatory: true,
    label: 'English',
    flag: '/img/languagesFlags/en.svg',
  },
  {
    id: SupportedLanguageDto.It.toString(),
    language: SupportedLanguageDto.It,
    languageShort: i18nLocale.It,
    languageLongEnglish: 'Italian',
    languageLongLocale: 'Italian',
    languagePhoneIso2: 'IT',
    isMandatory: true,
    label: 'Italian',
    flag: '/img/languagesFlags/it.svg',
  },
]

const DEFAULT_LOCALE = (Object.values(i18nLocale).find((l) => window.navigator.language.includes(l)) ??
  i18nLocale.En) as i18nLocale
const OTA_HASH = 'ed0d26c6406b5d2c78dc2f8zunn'

const client = new OtaClient(OTA_HASH)

const currentLocale = ref(DEFAULT_LOCALE)
const remoteLocaleLoaded = ref(false)

export default () => {
  function isLocaleSupported(locale: i18nLocale) {
    return SUPPORTED_LOCALES.some(({ languageShort }) => languageShort === locale)
  }

  function isLocaleToShowTranslatedKeys(locale: i18nLocale) {
    return locale === i18nLocale.Custom
  }

  function getUserLocale(): {
    locale: i18nLocale
    localeNoRegion: i18nLocale
  } {
    const locale = DEFAULT_LOCALE

    return {
      locale,
      localeNoRegion: locale.split('-')[0] as i18nLocale,
    }
  }

  function getPersistedLocale(): i18nLocale | null {
    const persistedLocale = localStorage.getItem('language')

    if (persistedLocale && isLocaleSupported(persistedLocale as i18nLocale)) {
      return persistedLocale as i18nLocale
    }
    return null
  }

  function getDefaultLocale(): i18nLocale {
    const userPersistedLocale = getPersistedLocale()
    if (userPersistedLocale) {
      return userPersistedLocale
    }

    const userPreferredLocale = getUserLocale()

    if (isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale
    }

    if (isLocaleSupported(userPreferredLocale.localeNoRegion)) {
      return userPreferredLocale.localeNoRegion
    }

    return DEFAULT_LOCALE
  }

  function getLanguageStringFromDto(language: FiftyApiClients.SupportedLanguageDto | null): i18nLocale {
    if (language === null || language === undefined) {
      return DEFAULT_LOCALE
    }

    const locale = SUPPORTED_LOCALES.find((x) => x.language === parseInt(language.toString()))
    if (!locale) {
      return DEFAULT_LOCALE
    }

    return locale.languageShort as i18nLocale
  }

  function getLanguageDtoFromString(language: i18nLocale): SupportedLanguageDto {
    const languageFound = SUPPORTED_LOCALES.find((x) => x.languageShort === language)
    if (languageFound !== undefined) {
      return languageFound.language
    }

    return SupportedLanguageDto.En
  }

  function getSupportedLanguage(
    language: FiftyApiClients.SupportedLanguageDto | null | undefined,
  ): SupportedLanguage | null | undefined {
    if (language === null || language === undefined) {
      return null
    }

    return SUPPORTED_LOCALES.find((x) => x.language === language)
  }

  function setLocale(locale: i18nLocale) {
    currentLocale.value = locale
    i18n.global.locale = locale
    dayjs.locale(locale)

    if (!isLocaleToShowTranslatedKeys(locale)) {
      localStorage.setItem('language', locale)
      document.querySelector('html')?.setAttribute('lang', locale)
    }
  }

  function loadLocalTranslations(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (remoteLocaleLoaded.value) {
        resolve(true)
        return
      }
      try {
        for (const lang of SUPPORTED_LOCALES) {
          if (i18n.global.availableLocales.includes(lang.languageShort)) {
            continue
          } else {
            const jsons = import.meta.glob('@/locales/*.json', { eager: true })
            // resolve locally if can't get it from CDN
            const langMessages = JSON.parse(JSON.stringify(jsons[`/src/locales/${lang.languageShort}.json`]))
            i18n.global.setLocaleMessage(lang.languageShort, langMessages)
          }
        }
        i18n.global.silentTranslationWarn = false
        i18n.global.silentFallbackWarn = false
        resolve(true)
      } catch (e) {
        reject(e)
      }
    })
  }

  async function loadRemoteTranslation() {
    try {
      if (remoteLocaleLoaded.value) {
        return
      }

      const contentLanguages = await client.getContent()
      const supportedLocalesShort = SUPPORTED_LOCALES.map(({ languageShort }) => languageShort)

      for (const [languageKey, languageFilesUrl] of Object.entries(contentLanguages)) {
        for (const languageFileUrl of languageFilesUrl) {
          // The other file is for the backend
          if (languageFileUrl.includes('Fifty.Web') && supportedLocalesShort.includes(languageKey as i18nLocale)) {
            const languageFile = await client.getFileTranslations(languageFileUrl)

            // as crowdin send es-ES for spanish
            const [locale] = languageKey.split('-')
            i18n.global.setLocaleMessage(locale, languageFile)
          }
        }
      }
      remoteLocaleLoaded.value = true
    } catch (e) {
      // do not use $logger here, because it's not initialized yet
      Sentry.captureException(e)
    }
  }

  return {
    currentLocale,
    setLocale,
    isLocaleSupported,
    isLocaleToShowTranslatedKeys,
    getDefaultLocale,
    getSupportedLanguage,
    getLanguageStringFromDto,
    getLanguageDtoFromString,
    loadLocalTranslations,
    loadRemoteTranslation,
  }
}
