import type { RouteRecordRaw } from 'vue-router'
import { ErrorType } from '@/types/ErrorPage.d'
import i18nSetup from '@/i18n'

export const PAGE_NOT_FOUND = 'notFound'
export const PAGE_ERROR = 'error'
export const PAGE_NETWORK_RESOURCE_ERROR = 'network-error'
export const PAGE_FORBIDDEN = 'forbidden'

export const PAGE_SESSIONS_ON_GOING_CHALLENGES = 'onGoingChallengesPage'
export const PAGE_SESSIONS_CHALLENGE_CHOICES = 'challengeChoicesPage'

export const PAGE_MAGIC_LINK_LOGIN = 'magic-link-login'
export const PAGE_SSO_LINK_LOGIN = 'sso-link-login'
export const PAGE_RECOMMENDED_CHALLENGE = 'recommended-challenge'
export const PAGE_VALIDATED_RECOMMENDED_CHALLENGE = 'validated-recommended-challenge'
export const PAGE_ACCEPTED_RECOMMENDED_CHALLENGE = 'accepted-recommended-challenge'
export const PAGE_TEAM_CHALLENGE = 'team-challenge'
export const PAGE_USER_TEAM_DETAILS = 'user-team'
export const PAGE_VALIDATED_TEAM_CHALLENGE = 'validated-team-challenge'
export const PAGE_ACCEPTED_TEAM_CHALLENGE = 'accepted-team-challenge'
export const PAGE_VALIDATE_CHALLENGE_LINK = 'validate-challenge-link'
export const PAGE_SESSIONS_CHALLENGES_DONE = 'challengesDone'
export const PAGE_PROGRESSION = 'progression'
export const PAGE_PROGRESSION_PILLAR_DETAILS = 'progression-pillar-details'
export const PAGE_ACCOUNT = 'account'
export const PAGE_CONGRATULATIONS = 'congratulations'
export const PAGE_CONGRATULATIONS_FEEDBACK = 'congratulations-feedback'
export const PAGE_CLOSED_CHALLENGE = 'closed-challenge'
export const PAGE_FIRST_ACCEPTED_CHALLENGE = 'first-accepted-challenge'
export const PAGE_HABIT_CONGRATULATION = 'habit-congratulation-page'
export const PAGE_HABIT_CREATION = 'habit-creation'
export const PAGE_GROUPS = 'groups'
export const PAGE_ACCEPT_GROUP_ACTION = 'accept-group-action'
export const PAGE_VALIDATE_GROUP_ACTION = 'validate-group-action'
export const PAGE_GROUPS_DETAILS = 'groups-details'
export const PAGE_ACTION_GROUP_DETAILS = 'action-group-details'

export const PAGE_LOGIN = 'login'
export const PAGE_LOGIN_MFA = 'login-mfa'
export const PAGE_RESET_PASSWORD = 'reset-password'
export const PAGE_RESET_PASSWORD_REQUEST = 'reset-password-request'

export const PAGE_SSO_LOGIN_RESPONSE_SUCCESS = 'sso-login-response-success'
export const PAGE_SSO_LOGIN_REDIRECT = 'sso-login-redirect'
export const PAGE_SSO_LOGOUT_CALLBACK = 'sso-logout-callback'
export const PAGE_SSO_OPEN_SAFARI = 'sso-open-safari'
export const PAGE_FORCE_LOGOUT = 'force-logout-page'
export const PAGE_SSO_SAFARI_SUCCESS = 'sso-safari-success'
export const PAGE_SSO_ANDROID_SUCCESS = 'sso-android-success'
export const PAGE_USER_NO_PROGRAM = 'user-no-program'
export const PAGE_USER_CAMPAIGN_DONE = 'user-campaign-done'
export const PAGE_USER_WITH_TEAMS_SIGNUP = 'user-with-teams-signup'

export const PAGE_ON_BOARDING_INTRODUCTION = 'on-boarding-introduction'
export const PAGE_ON_BOARDING_LAUNCH_DESCRIPTION = 'on-boarding-launch-description'
export const PAGE_ON_BOARDING_CAMPAIGN_DESCRIPTION = 'on-boarding-campaign-description'
export const PAGE_ON_BOARDING_FIRST_LOGIN = 'on-boarding-first-login'
export const PAGE_ON_BOARDING_COURSE_DESCRIPTION = 'on-boarding-course-description'
export const PAGE_ON_BOARDING_SEGMENTS = 'on-boarding-segments'
export const PAGE_ON_BOARDING_CHALLENGE_CHOICE = 'on-boarding-challenge-choice'
export const PAGE_ON_BOARDING_ACCEPT_CHALLENGE = 'on-boarding-accept-challenge'
export const PAGE_ON_BOARDING_CONGRATULATIONS = 'on-boarding-congratulations'
export const PAGE_ON_BOARDING_LEARNING_METHOD = 'on-boarding-learning-method'
export const PAGE_ON_BOARDING_HOW_TO = 'on-boarding-how-to'
export const PAGE_ON_BOARDING_PILLAR_SELECT = 'on-boarding-select-pillar'
export const PAGE_ON_BOARDING_MANAGER_PROFILE_SELECT = 'on-boarding-manager-profile'
export const PAGE_ON_BOARDING_ACTIONS_SELECT = 'on-boarding-content-select'

export const PAGE_BACKOFFICE_USERS = 'backofficeUsers'
export const PAGE_BACKOFFICE_CREATE_USER = 'backofficeCreateUser'
export const PAGE_BACKOFFICE_SINGLE_USER = 'backofficeSingleUser'
export const PAGE_BACKOFFICE_CHALLENGE_REVIEW = 'backofficeChallengeReview'
export const PAGE_BACKOFFICE_CHALLENGE_REVIEW_GLOBAL_VIEW = 'backofficeChallengeReviewGlobalView'
export const PAGE_BACKOFFICE_CLIENTS = 'backofficeClients'
export const PAGE_BACKOFFICE_ENTERPRISES = 'backofficeEnterprises'
export const PAGE_BACKOFFICE_CLIENT_DETAIL = 'backofficeClientDetail'
export const PAGE_BACKOFFICE_COURSE_DETAIL = 'backofficeProgramContentDetail'
export const PAGE_BACKOFFICE_PROGRAM_ACTIONS_DETAIL = 'backofficeProgramContentDetail'
export const PAGE_BACKOFFICE_PROGRAM_CONTEXT_DETAIL = 'backofficeProgramContextDetail'
export const PAGE_BACKOFFICE_CHALLENGES = 'backofficeChallenges'
export const PAGE_BACKOFFICE_CHALLENGE_TAGS = 'backofficeChallengeTags'
export const PAGE_BACKOFFICE_FILES = 'backofficeFiles'
export const PAGE_BACKOFFICE_BASE_ACTION_EDITION_PAGE = 'backofficeBaseActionEditionPage'
export const PAGE_BACKOFFICE_MESSAGING_HISTORIC = 'backofficeMessagingHistoric'
export const PAGE_BACKOFFICE_USER_SESSION_HISTORIC = 'backofficeSessionHistoric'
export const PAGE_BACKOFFICE_CONNECTION_HISTORIC = 'backofficeConnectionHistoric'
export const PAGE_BACKOFFICE_LAUNCHES = 'backofficeLaunches'
export const PAGE_BACKOFFICE_LAUNCH_DETAIL = 'backofficeLaunchDetail'
export const PAGE_BACKOFFICE_CAMPAIGNS = 'backofficeCampaigns'
export const PAGE_BACKOFFICE_CAMPAIGN_DETAIL = 'backofficeCampaignDetail'
export const PAGE_BACKOFFICE_PROGRAM_DETAIL = 'backofficeProgramDetail'
export const PAGE_BACKOFFICE_SKILL_ANALYTICS = 'backofficeSkillAnalytics'
export const PAGE_BACKOFFICE_CHALLENGE_TAG_MANAGER = 'backofficeChallengeTagManager'
export const PAGE_BACKOFFICE_LLM_ACTION_GENERATION = 'backofficeLlmActionGeneration'
export const PAGE_BACKOFFICE_LLM_ACTION_MATCHING = 'backofficeLlmActionMatching'
export const PAGE_BACKOFFICE_DEFAULT_COMMUNICATION_TEMPLATE = 'backofficeDefaultCommunicationTemplate'

export const PAGE_ADMIN_CHALLENGE_REVIEW = 'admin-challenge-review'
export const PAGE_ADMIN_CHALLENGE_REVIEW_GLOBAL_VIEW = 'admin-challenge-review-global-view'
export const PAGE_ADMIN_CHALLENGE_REVIEW_CHALLENGE_DETAILS = 'admin-challenge-review-challenge_details'
export const PAGE_ADMIN_CHALLENGE_REVIEW_NOTIFICATIONS = 'admin-challenge-review-notifications'
export const PAGE_ADMIN_RESULT = 'admin-dashboard-result'
export const PAGE_ADMIN_KPI_PRACTICE = 'admin-dashboard-kpi-practice'
export const PAGE_ADMIN_KPI_PILLARS = 'admin-dashboard-kpi-pillars'
export const PAGE_ADMIN_KPI_ADOPTION = 'admin-dashboard-kpi-adoption'
export const PAGE_ADMIN_COMPARE_SEGMENTS = 'admin-dashboard-compare-segments'
export const PAGE_ADMIN_HOME = 'admin-home'
export const PAGE_ADMIN_PROGRAMS = 'admin-programs'
export const PAGE_ADMIN_PROGRAM = 'admin-program'
export const PAGE_ADMIN_PROGRAM_OVERVIEW = 'admin-program-overview'
export const PAGE_ADMIN_PROGRAM_POPULATION = 'admin-program-population'
export const PAGE_ADMIN_PROGRAM_INFORMATIONS = 'admin-program-informations'
export const PAGE_ADMIN_PROGRAM_ACTIONS = 'admin-program-content'
export const PAGE_ADMIN_PROGRAM_SEGMENTS = 'admin-program-segments'
export const PAGE_ADMIN_CREATE_PROGRAM = 'admin-create-program'

export const PAGE_ADMIN_PROGRAM_COMMUNICATION = 'admin-program-communication'
export const PAGE_ADMIN_PROGRAM_CONTEXT = 'admin-program-context'
export const PAGE_ADMIN_PROGRAM_COMMUNICATION_EXPERIENCE = 'admin-program-com-experience'
export const PAGE_ADMIN_PROGRAM_CAMPAIGNS = 'admin-program-campaigns'
export const PAGE_ADMIN_PROGRAM_CAMPAIGN_DETAILS = 'admin-program-campaign-details'
export const PAGE_ADMIN_PROGRAM_KPI = 'admin-program-kpi'
export const PAGE_ADMIN_PROGRAM_KPI_PRACTICE = 'admin-program-kpi-practice'
export const PAGE_ADMIN_PROGRAM_KPI_PILLARS = 'admin-program-kpi-pillars'
export const PAGE_ADMIN_PROGRAM_KPI_ADOPTION = 'admin-program-kpi-adoption'
export const PAGE_ADMIN_CAMPAIGNS = 'admin-campaigns'
export const PAGE_ADMIN_SINGLE_CAMPAIGN = 'admin-single-campaign'
export const PAGE_ADMIN_SINGLE_USER = 'admin-single-user'
export const PAGE_ADMIN_ACTION_REPOSITORY = 'admin-action-repository'
export const PAGE_ADMIN_ACCOUNT = 'admin-account'
export const PAGE_ADMIN_EXPIRED_CONTRACT = 'admin-expired-contract'
export const PAGE_ADMIN_USERS = 'admin-users'
export const PAGE_ADMIN_SEGMENTS = 'admin-segments'
export const PAGE_ADMIN_SEGMENT_DETAILS = 'admin-segment-details'

export const PAGE_SESSIONS_NEW_SESSION = 'new-session'

// Expired license
export const PAGE_EXPIRED_LICENSE = 'expired-license'

// Url
export const PATH_SESSIONS_ON_GOING_CHALLENGES = 'challenges'
export const ALIAS_PROGRESSION_GLOBAL = 'progression-global/'

export const adminCoursePages = [
  PAGE_ADMIN_CHALLENGE_REVIEW_GLOBAL_VIEW,
  PAGE_ADMIN_CHALLENGE_REVIEW_CHALLENGE_DETAILS,
  PAGE_ADMIN_CHALLENGE_REVIEW_NOTIFICATIONS,
  PAGE_ADMIN_PROGRAMS,
  PAGE_ADMIN_PROGRAM_ACTIONS,
  PAGE_ADMIN_PROGRAM_COMMUNICATION,
  PAGE_ADMIN_PROGRAM_CAMPAIGNS,
  PAGE_ADMIN_PROGRAM_CAMPAIGN_DETAILS,
  PAGE_ADMIN_PROGRAM_KPI,
  PAGE_ADMIN_PROGRAM_KPI_PRACTICE,
  PAGE_ADMIN_PROGRAM_KPI_PILLARS,
  PAGE_ADMIN_PROGRAM_KPI_ADOPTION,
  PAGE_ADMIN_PROGRAM_OVERVIEW,
  PAGE_ADMIN_PROGRAM_INFORMATIONS,
  PAGE_ADMIN_PROGRAM_SEGMENTS,
]

export const lastObStep = 8

// Meta vars
const isObStep = true
const isSignUpPage = true
const noDefaultMargin = true
const allowUserNotLoggedIn = true
const isAdmin = true
const displayObBackground = true
const hideMenuItems = true
const displayHomePageBackground = true
const stopRefreshLogin = true
const loggedOutOnly = true
const isBackoffice = true
const congratulationPage = true

const requiresAuth = true

export const routes = <RouteRecordRaw[]>[
  {
    path: '/:lang?',
    component: () => import('@/layouts/AppLayout.vue'),
    children: [
      {
        name: PAGE_NETWORK_RESOURCE_ERROR,
        path: 'network-error',
        component: () => import('@/components/generics/errors/errorPage/ErrorPage.vue'),
        props: { errorType: ErrorType.Network },
      },
      // MainLayout
      {
        path: '',
        component: () => import('@/layouts/MainLayout.vue'),
        meta: { requiresAuth },
        children: [
          {
            name: PAGE_SESSIONS_ON_GOING_CHALLENGES,
            path: 'challenges',
            component: () => import('@/components/challenges/MyChallengesPage/MyOnGoingChallengesPage.vue'),
            meta: {
              noDefaultMargin,
              displayHomePageBackground,
            },
          },
          {
            name: PAGE_SESSIONS_CHALLENGE_CHOICES,
            path: 'challenge-choices',
            component: () => import('@/components/challenges/MyChallengesPage/MyChallengeChoicesPage.vue'),
            meta: {
              noDefaultMargin,
              displayHomePageBackground,
            },
          },
          {
            name: PAGE_SESSIONS_CHALLENGES_DONE,
            path: 'completed-challenges',
            component: () => import('@/components/employee/myActions/MyActionsPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_PROGRESSION,
            path: 'progression',
            alias: [ALIAS_PROGRESSION_GLOBAL],
            component: () => import('@/components/progression/ProgressionPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_PROGRESSION_PILLAR_DETAILS,
            path: 'progression/pillars/:pillarId',
            component: () => import('@/components/progression/pillarDetails/PillarDetailsPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_FIRST_ACCEPTED_CHALLENGE,
            path: 'first-accepted-challenge',
            component: () =>
              import(
                '@/components/challenges/congratulations/firstAcceptation/FirstAcceptedChallengeCongratulation.vue'
              ),
            meta: {
              congratulationPage,
            },
          },
          {
            name: PAGE_HABIT_CREATION,
            path: 'habit-creation/:shortChallengeId',
            component: () => import('@/components/challenges/congratulations/habitCreationPage/HabitCreationPage.vue'),
            meta: {
              congratulationPage,
            },
          },
          {
            name: PAGE_HABIT_CONGRATULATION,
            path: 'habit-congratulation',
            component: () =>
              import('@/components/challenges/congratulations/habitCongratulationPage/HabitCongratulationPage.vue'),
            meta: {
              congratulationPage,
            },
          },
          {
            name: PAGE_CONGRATULATIONS,
            path: 'congratulations/:shortChallengeId/success',
            component: () => import('@/components/challenges/congratulations/Congratulations.vue'),
            meta: {
              congratulationPage,
            },
          },
          {
            name: PAGE_CLOSED_CHALLENGE,
            path: 'congratulations/:shortChallengeId/closed',
            component: () => import('@/components/challenges/congratulations/closed/ClosedChallengePage.vue'),
            meta: {
              congratulationPage,
            },
          },
          {
            name: PAGE_CONGRATULATIONS_FEEDBACK,
            path: 'congratulations/:shortChallengeId/feedback',
            component: () =>
              import('@/components/challenges/congratulations/congratulationFeedback/CongratulationsFeedBackPage.vue'),
            meta: {
              congratulationPage,
            },
          },
          {
            name: PAGE_ACCOUNT,
            path: 'account',
            component: () => import('@/components/account/AccountPage.vue'),
            meta: { noDefaultMargin },
          },
          {
            name: PAGE_SESSIONS_NEW_SESSION,
            path: 'new-session',
            component: () => import('@/components/newSession/NewSessionPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_GROUPS,
            path: 'groups',
            component: () => import('@/components/groups/GroupsPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },

          {
            name: PAGE_VALIDATE_GROUP_ACTION,
            path: 'groups/:groupId/actions/:groupUserActionId/validate',
            component: () => import('@/components/groups/ValidateGroupActionPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
        ],
      },

      // LegagyLayout need to be cleaned step by step in other layouts
      {
        path: '',
        component: () => import('@/layouts/LegacyLayout.vue'),
        children: [
          {
            name: PAGE_MAGIC_LINK_LOGIN,
            path: 'unique-login-link',
            component: () => import('@/components/unloggedPages/MagicLinkPage.vue'),
            meta: { allowUserNotLoggedIn },
          },
          {
            name: PAGE_SSO_LINK_LOGIN,
            path: 'sso-login-link',
            component: () => import('@/components/unloggedPages/SsoLinkLoginPage.vue'),
            meta: { allowUserNotLoggedIn },
          },
          {
            name: PAGE_SSO_LOGIN_RESPONSE_SUCCESS,
            path: 'sso/saml/success',
            component: () => import('@/components/unloggedPages/login/SsoSamlLoginResponsePage.vue'),
            meta: { allowUserNotLoggedIn, stopRefreshLogin },
          },
          {
            name: PAGE_SSO_LOGIN_REDIRECT,
            path: 'sso/saml/redirect',
            component: () => import('@/components/unloggedPages/login/SsoSamlRedirectPage.vue'),
            meta: { allowUserNotLoggedIn, stopRefreshLogin },
          },
          {
            name: PAGE_SSO_LOGOUT_CALLBACK,
            path: 'sso/saml/logout',
            component: () => import('@/components/externalCallbacks/SsoSamlLogoutCallback.vue'),
            meta: { requiresAuth },
          },
          {
            name: PAGE_SSO_OPEN_SAFARI,
            path: 'sso/open-safari',
            component: () => import('@/components/unloggedPages/login/SsoOpenSafariPage.vue'),
          },
          {
            name: PAGE_SSO_SAFARI_SUCCESS,
            path: 'sso/safari/success',
            component: () => import('@/components/externalCallbacks/SsoSafariSuccessPage.vue'),
            meta: { noDefaultMargin, hideMenuItems },
          },
          {
            name: PAGE_SSO_ANDROID_SUCCESS,
            path: 'sso/android/success',
            component: () => import('@/components/externalCallbacks/SsoSafariSuccessPage.vue'),
            meta: { noDefaultMargin, hideMenuItems },
          },
          {
            name: PAGE_FORCE_LOGOUT,
            path: 'user/s/logout',
            component: () => import('@/components/challenges/MyChallengesPage/MyOnGoingChallengesPage.vue'),
            meta: { allowUserNotLoggedIn },
          },
          {
            name: PAGE_USER_NO_PROGRAM,
            path: 'user-no-program',
            component: () => import('@/components/userNoProgram/UserNoProgramPage.vue'),
            meta: { requiresAuth, isSignUpPage },
          },
          {
            name: PAGE_USER_CAMPAIGN_DONE,
            path: 'user-campaign-done',
            component: () => import('@/components/userWithPastCampaign/UserWithPastCampaignPage.vue'),
            meta: { requiresAuth, isSignUpPage },
          },
          {
            name: PAGE_USER_WITH_TEAMS_SIGNUP,
            path: 'user-with-teams-signup',
            component: () => import('@/components/userWithTeams/UserWithTeamsSignupPage.vue'),
            meta: { isSignUpPage },
          },
          {
            name: PAGE_VALIDATE_CHALLENGE_LINK,
            path: 'cha/:shortChallengeId/validate',
            component: () =>
              import('@/components/challenges/MyChallengesPage/validateChallengeLink/ValidateChallengeLinkPage.vue'),
            meta: { requiresAuth },
          },
          {
            name: PAGE_ACCEPTED_RECOMMENDED_CHALLENGE,
            path: 'iss/:shortChallengeId/accept',
            component: () =>
              import(
                '@/components/challenges/MyChallengesPage/recommendedChallenges/RecommendedChallengeAcceptationPage.vue'
              ),
            meta: { allowUserNotLoggedIn, congratulationPage },
          },
          {
            name: PAGE_VALIDATED_RECOMMENDED_CHALLENGE,
            path: 'iss/:shortChallengeId/validate',
            component: () =>
              import('@/components/challenges/congratulations/recommended/ValidatedRecommendedChallengePage.vue'),
            meta: { allowUserNotLoggedIn, congratulationPage },
          },
          {
            name: PAGE_RECOMMENDED_CHALLENGE,
            path: 'iss/:shortChallengeId',
            component: () => import('@/components/challenges/ChallengePage/RecommendedChallengePage.vue'),
            meta: { allowUserNotLoggedIn, congratulationPage },
          },
          {
            name: PAGE_ACCEPTED_TEAM_CHALLENGE,
            path: 'team/:shortChallengeId/accept',
            redirect() {
              return { name: PAGE_SESSIONS_ON_GOING_CHALLENGES }
            },
            meta: { allowUserNotLoggedIn, congratulationPage },
          },
          {
            name: PAGE_VALIDATED_TEAM_CHALLENGE,
            path: 'team/:shortChallengeId/validate',
            redirect() {
              return { name: PAGE_SESSIONS_ON_GOING_CHALLENGES }
            },
            meta: { allowUserNotLoggedIn, displayHomePageBackground },
          },
          {
            name: PAGE_TEAM_CHALLENGE,
            path: 'team/:shortChallengeId',
            redirect() {
              return { name: PAGE_SESSIONS_ON_GOING_CHALLENGES }
            },
            meta: { allowUserNotLoggedIn, displayHomePageBackground },
          },
          {
            name: PAGE_USER_TEAM_DETAILS,
            path: 'team-challenge/:shortChallengeId',
            redirect() {
              return { name: PAGE_SESSIONS_ON_GOING_CHALLENGES }
            },
            meta: { requiresAuth, displayHomePageBackground },
          },
          {
            name: PAGE_GROUPS_DETAILS,
            path: 'groups/:groupId',
            component: () => import('@/components/groups/GroupDetailsPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_ACTION_GROUP_DETAILS,
            path: 'groups/:groupId/actions/:actionGroupId',
            component: () => import('@/components/groups/ActionGroupDetailsPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_ACCEPT_GROUP_ACTION,
            path: 'groups/:groupId/actions/:groupUserActionId/accept',
            component: () => import('@/components/groups/AcceptGroupActionPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_ON_BOARDING_CAMPAIGN_DESCRIPTION,
            path: 'on-boarding/launch-description',
            redirect() {
              return { name: PAGE_ON_BOARDING_CAMPAIGN_DESCRIPTION }
            },
          },
          {
            name: PAGE_ON_BOARDING_CAMPAIGN_DESCRIPTION,
            path: 'on-boarding/campaign-description',
            component: () => import('@/components/onBoarding/CampaignDescription/ObCampaignDescription.vue'),
            meta: { requiresAuth, isSignUpPage },
          },
          {
            name: PAGE_ON_BOARDING_INTRODUCTION,
            path: 'on-boarding/introduction', // Path used in linkCreator
            component: () => import('@/components/onBoarding/Introduction/ObIntroductionPage.vue'),
            meta: {
              isObStep,
              noDefaultMargin,
              isSignUpPage,
            },
          },
          {
            name: PAGE_ON_BOARDING_LEARNING_METHOD,
            path: 'on-boarding/learning-method',
            component: () => import('@/components/onBoarding/LearningMethod/ObLearningMethodPage.vue'),
            meta: {
              requiresAuth,
              noDefaultMargin,
              isObStep,
              displayObBackground,
            },
          },
          {
            name: PAGE_ON_BOARDING_HOW_TO,
            path: 'on-boarding/how-to',
            component: () => import('@/components/onBoarding/HowTo/ObHowToPage.vue'),
            meta: {
              requiresAuth,
              noDefaultMargin,
              isObStep,
              displayObBackground,
            },
          },
          {
            name: PAGE_ON_BOARDING_FIRST_LOGIN,
            path: 'first-login',
            component: () => import('@/components/onBoarding/Introduction/ObIntroductionPage.vue'),
            meta: {
              noDefaultMargin,
              isSignUpPage,
              isObStep,
              displayObBackground,
            },
          },
          {
            name: PAGE_ON_BOARDING_COURSE_DESCRIPTION,
            path: 'on-boarding/course-description',
            component: () => import('@/components/onBoarding/CourseDescription/ObCourseDescriptionPage.vue'),
            meta: {
              requiresAuth,
              isObStep,
              noDefaultMargin,
              displayObBackground,
            },
          },
          {
            name: PAGE_ON_BOARDING_SEGMENTS,
            path: 'on-boarding/segments',
            component: () => import('@/components/onBoarding/Segments/ObSegmentsPage.vue'),
            meta: {
              requiresAuth,
              isObStep,
              noDefaultMargin,
              displayObBackground,
            },
          },
          {
            name: PAGE_ON_BOARDING_PILLAR_SELECT,
            path: 'on-boarding/select-pillar',
            component: () => import('@/components/onBoarding/Pillar/ObPillarSelectPage.vue'),
            meta: {
              requiresAuth,
              isObStep,
              noDefaultMargin,
              displayObBackground,
            },
          },
          {
            name: PAGE_ON_BOARDING_CHALLENGE_CHOICE,
            path: 'on-boarding/choice',
            component: () => import('@/components/onBoarding/Choice/ObChallengeChoicePage.vue'),
            meta: {
              requiresAuth,
              isObStep,
              noDefaultMargin,
              displayObBackground,
            },
          },
          {
            name: PAGE_ON_BOARDING_ACCEPT_CHALLENGE,
            path: 'on-boarding/accept/:shortChallengeId',
            component: () => import('@/components/onBoarding/Choice/ObAcceptChallengePage.vue'),
            meta: {
              requiresAuth,
              isObStep,
              noDefaultMargin,
              displayObBackground,
            },
          },
          {
            name: PAGE_ON_BOARDING_CONGRATULATIONS,
            path: 'on-boarding/congratulations',
            component: () => import('@/components/onBoarding/Choice/ObCongratulationsPage.vue'),
            meta: {
              requiresAuth,
              isObStep,
              noDefaultMargin,
              congratulationPage,
              displayObBackground,
            },
          },
          {
            name: PAGE_LOGIN,
            path: 'login',
            component: () => import('@/components/unloggedPages/login/LoginPage.vue'),
            meta: { loggedOutOnly, allowUserNotLoggedIn },
          },
          {
            name: PAGE_LOGIN_MFA,
            path: 'account/mfa',
            component: () => import('@/components/unloggedPages/MfaLoginPage.vue'),
            meta: { allowUserNotLoggedIn },
          },
          {
            name: PAGE_RESET_PASSWORD,
            path: 'account/reset',
            component: () => import('@/components/unloggedPages/login/ResetPasswordPage.vue'),
          },
          {
            name: PAGE_RESET_PASSWORD_REQUEST,
            path: 'reset-password-request',
            component: () => import('@/components/unloggedPages/login/ResetPasswordRequestPage.vue'),
            meta: { loggedOutOnly },
          },
          {
            name: PAGE_ERROR,
            path: 'error',
            component: () => import('@/components/generics/errors/errorPage/ErrorPage.vue'),
            props: { errorType: ErrorType.Internal },
          },
          {
            name: PAGE_FORBIDDEN,
            path: 'forbidden',
            component: () => import('@/components/generics/errors/errorPage/ErrorPage.vue'),
            props: { errorType: ErrorType.Forbidden },
          },

          {
            name: PAGE_EXPIRED_LICENSE,
            path: 'expired-license',
            component: () => import('@/components/expired/ExpiredLicensePage.vue'),
            meta: { requiresAuth },
          },
        ],
      },

      // Backoffice
      {
        path: 'backoffice',
        component: () => import('@/layouts/BackofficeLayout.vue'),
        meta: { requiresAuth, isBackoffice },
        redirect: () => ({ name: PAGE_BACKOFFICE_USERS }),
        children: [
          {
            name: PAGE_BACKOFFICE_USERS,
            path: 'users',
            component: () => import('@/components/backoffice/users/usersPage/BackofficeUsersPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_CREATE_USER,
            path: 'users/new',
            component: () => import('@/components/backoffice/users/createUserPage/CreateUserPage.vue'),
          },
          {
            name: PAGE_BACKOFFICE_SINGLE_USER,
            path: 'users/:userId',
            component: () => import('@/components/backoffice/users/singleUserPage/SingleUserPage.vue'),
          },
          {
            name: PAGE_BACKOFFICE_CHALLENGE_REVIEW,
            path: 'challenge-review/:clientId?',
            component: () => import('@/components/admin/challengeReview/AdminChallengeReviewList.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_CHALLENGE_REVIEW_GLOBAL_VIEW,
            path: 'challenge-review-global-view/:programId/:clientId?',
            component: () => import('@/components/admin/challengeReview/AdminProgramActionsReview.vue'),
            meta: {
              noDefaultMargin,
              isBackoffice,
            },
          },
          {
            name: PAGE_BACKOFFICE_CLIENTS,
            path: 'clients',
            component: () => import('@/components/backoffice/clients/BackofficeClientsListPage.vue'),
            meta: { noDefaultMargin },
          },
          {
            name: PAGE_BACKOFFICE_ENTERPRISES,
            path: 'enterprises',
            component: () => import('@/components/backoffice/enterprises/BackofficeEnterprisesListPage.vue'),
          },
          {
            name: PAGE_BACKOFFICE_CLIENT_DETAIL,
            path: 'clients/:clientId',
            component: () => import('@/components/backoffice/clients/detail/BackofficeClientDetail.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_PROGRAM_ACTIONS_DETAIL,
            path: 'programs/:programId/content/:programActionId?',
            component: () => import('@/components/backoffice/programs/content/BackofficeProgramContentPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_PROGRAM_CONTEXT_DETAIL,
            path: 'programs/:programId/context/:programContextId?',
            component: () => import('@/components/backoffice/programs/details/BackofficeProgramContextPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_PROGRAM_DETAIL,
            path: 'programs/:programId',
            component: () => import('@/components/backoffice/programs/details/BackofficeProgramDetailPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_CHALLENGES,
            path: 'challenges',
            component: () => import('@/components/backoffice/challenges/BackofficeChallengesPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_CHALLENGE_TAGS,
            path: 'challenge-tags',
            component: () => import('@/components/backoffice/tags/BackofficeTagManagementPage.vue'),
          },
          {
            name: PAGE_BACKOFFICE_FILES,
            path: 'files',
            component: () => import('@/components/backoffice/files/BackofficeFileManagementPage.vue'),
          },
          {
            name: PAGE_BACKOFFICE_BASE_ACTION_EDITION_PAGE,
            path: 'challenges/challenge-edition/:baseActionId?',
            component: () => import('@/components/backoffice/challenges/detail/BackofficeBaseActionEditionPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_MESSAGING_HISTORIC,
            path: 'messaging-historic/:userId',
            component: () => import('@/components/backoffice/messaging/BackofficeMessagingHistoricPage.vue'),
          },
          {
            name: PAGE_BACKOFFICE_CONNECTION_HISTORIC,
            path: 'connection-historic/:userId',
            component: () =>
              import('@/components/backoffice/users/singleUserPage/historics/BackofficeConnectionHistoricPage.vue'),
          },
          {
            name: PAGE_BACKOFFICE_USER_SESSION_HISTORIC,
            path: 'user-session-historic/:userId',
            component: () => import('@/components/backoffice/userSession/BackofficeUserSessionHistoricPage.vue'),
          },
          {
            name: PAGE_BACKOFFICE_LAUNCHES,
            path: 'launches',
            redirect() {
              return { name: PAGE_BACKOFFICE_CAMPAIGNS }
            },
          },
          {
            name: PAGE_BACKOFFICE_LAUNCH_DETAIL,
            path: 'launch/:launchId',
            redirect() {
              return { name: PAGE_BACKOFFICE_CAMPAIGNS }
            },
          },
          {
            name: PAGE_BACKOFFICE_CAMPAIGNS,
            path: 'campaigns',
            component: () => import('@/components/backoffice/campaigns/BackofficeCampaignsPage.vue'),
            meta: { noDefaultMargin },
          },
          {
            name: PAGE_BACKOFFICE_CAMPAIGN_DETAIL,
            path: 'campaign/:campaignId',
            component: () => import('@/components/backoffice/campaigns/details/BackofficeCampaignDetail.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_SKILL_ANALYTICS,
            path: 'skill-analytics',
            component: () => import('@/components/backoffice/skillAnalytics/SkillAnalyticsPage.vue'),
          },
          {
            name: PAGE_BACKOFFICE_CHALLENGE_TAG_MANAGER,
            path: 'challenge-tag-manager',
            component: () => import('@/components/backoffice/challengeTagManager/ChallengeTagManager.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_LLM_ACTION_GENERATION,
            path: 'llm-action-generation',
            component: () => import('@/components/backoffice/Llm/ActionGenerationTopicPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_LLM_ACTION_MATCHING,
            path: 'llm-action-matching',
            component: () => import('@/components/backoffice/Llm/ActionMatcherPage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
          {
            name: PAGE_BACKOFFICE_DEFAULT_COMMUNICATION_TEMPLATE,
            path: 'default-communication-template',
            component: () => import('@/components/backoffice/communication/DefaultCommunicationTemplatePage.vue'),
            meta: {
              noDefaultMargin,
            },
          },
        ],
      },

      // Admin
      {
        path: 'admin',
        component: () => import('@/layouts/AdminLayout.vue'),
        alias: 'dashboard',
        meta: { requiresAuth, isAdmin },
        redirect: () => ({ name: PAGE_ADMIN_HOME }),
        children: [
          {
            name: PAGE_ADMIN_HOME,
            path: 'home',
            component: () => import('@/components/admin/home/AdminHome.vue'),
          },
          {
            name: PAGE_ADMIN_PROGRAMS,
            path: 'programs',
            component: () => import('@/components/admin/AdminPrograms.vue'),
          },
          {
            name: PAGE_ADMIN_PROGRAM,
            path: 'program/:programId',
            meta: { requiresAuth, isAdmin },
            component: () => import('@/layouts/AdminProgramLayout.vue'),
            redirect: () => ({ name: PAGE_ADMIN_PROGRAM_OVERVIEW }),
            children: [
              {
                name: PAGE_ADMIN_PROGRAM_OVERVIEW,
                path: 'overview',
                component: () => import('@/components/admin/program/AdminProgramOverview.vue'),
              },
              {
                name: PAGE_ADMIN_PROGRAM_POPULATION,
                path: 'population',
                meta: {
                  isOldRoute: true,
                },
                redirect: () => ({ name: PAGE_ADMIN_PROGRAM_INFORMATIONS }),
              },
              {
                name: PAGE_ADMIN_PROGRAM_INFORMATIONS,
                path: 'informations',
                component: () => import('@/components/admin/program/AdminProgramInformations.vue'),
              },

              {
                name: PAGE_ADMIN_PROGRAM_SEGMENTS,
                path: 'segments',
                component: () => import('@/components/admin/program/AdminProgramSegments.vue'),
              },
              {
                name: PAGE_ADMIN_PROGRAM_ACTIONS,
                path: 'actions',
                component: () => import('@/components/admin/program/AdminProgramActions.vue'),
              },
              {
                name: PAGE_ADMIN_PROGRAM_COMMUNICATION,
                path: 'communication',
                component: () => import('@/components/admin/program/communication/context/AdminCommunicationPage.vue'),
              },
              {
                name: PAGE_ADMIN_PROGRAM_CONTEXT,
                path: 'communication/contexts/:programContextId',
                component: () => import('@/components/admin/program/communication/context/AdminProgramContextPage.vue'),
              },
              {
                name: PAGE_ADMIN_PROGRAM_COMMUNICATION_EXPERIENCE,
                path: 'communication/experience',
                component: () => import('@/components/admin/program/communication/comkit/AdminProgramComKitPage.vue'),
              },
              {
                name: PAGE_ADMIN_PROGRAM_CAMPAIGNS,
                path: 'campaigns',
                component: () => import('@/components/admin/program/AdminProgramCampaigns.vue'),
              },
              {
                name: PAGE_ADMIN_PROGRAM_CAMPAIGN_DETAILS,
                path: 'campaigns/:campaignId',
                component: () => import('@/components/admin/program/AdminProgramCampaignDetails.vue'),
              },
              {
                name: PAGE_ADMIN_PROGRAM_KPI,
                path: 'kpi',
                component: () => import('@/components/admin/program/AdminProgramKpi.vue'),
              },
              {
                name: PAGE_ADMIN_PROGRAM_KPI_PRACTICE,
                path: 'kpis/practice',
                component: () => import('@/components/admin/kpi/AdminKpiPracticePage.vue'),
              },
              {
                name: PAGE_ADMIN_PROGRAM_KPI_PILLARS,
                path: 'kpis/pillars',
                component: () => import('@/components/admin/kpi/AdminKpiPillarsPage.vue'),
              },
              {
                name: PAGE_ADMIN_PROGRAM_KPI_ADOPTION,
                path: 'kpis/adoption',
                component: () => import('@/components/admin/kpi/AdminKpiAdoptionPage.vue'),
              },
              {
                name: PAGE_ADMIN_CREATE_PROGRAM,
                path: 'create',
                component: () => import('@/components/admin/create-program/AdminCreateProgram.vue'),
              },
            ],
          },
          {
            name: PAGE_ADMIN_CAMPAIGNS,
            path: 'campaigns',
            component: () => import('@/components/admin/campaigns/AdminCampaignsPage.vue'),
          },
          {
            path: 'launches',
            redirect() {
              return { name: PAGE_ADMIN_CAMPAIGNS }
            },
          },
          {
            name: PAGE_ADMIN_SINGLE_CAMPAIGN,
            path: 'launches/:launchId',
            redirect() {
              return { name: PAGE_ADMIN_PROGRAMS }
            },
          },
          {
            name: PAGE_ADMIN_RESULT,
            path: 'kpis',
            component: () => import('@/components/admin/kpi/home/AdminKpiHomePage.vue'),
          },
          {
            name: PAGE_ADMIN_KPI_PRACTICE,
            path: 'kpis/practice',
            component: () => import('@/components/admin/kpi/AdminKpiPracticePage.vue'),
          },
          {
            name: PAGE_ADMIN_KPI_PILLARS,
            path: 'kpis/pillars',
            component: () => import('@/components/admin/kpi/AdminKpiPillarsPage.vue'),
          },
          {
            name: PAGE_ADMIN_KPI_ADOPTION,
            path: 'kpis/adoption',
            component: () => import('@/components/admin/kpi/AdminKpiAdoptionPage.vue'),
          },
          {
            name: PAGE_ADMIN_ACTION_REPOSITORY,
            path: 'action-repository',
            component: () => import('@/components/admin/actionRepository/AdminActionRepository.vue'),
          },
          {
            name: PAGE_ADMIN_ACCOUNT,
            path: 'edit-account',
            component: () => import('@/components/admin/account/AdminAccount.vue'),
          },
          {
            name: PAGE_ADMIN_EXPIRED_CONTRACT,
            path: 'expired-contract',
            component: () => import('@/layouts/expired/AdminExpiredContractPage.vue'),
          },
          {
            name: PAGE_ADMIN_USERS,
            path: 'users',
            component: () => import('@/components/admin/users/AdminUsersPage.vue'),
          },
          {
            name: PAGE_ADMIN_SINGLE_USER,
            path: 'users/:userId',
            component: () => import('@/components/admin/users/singleUser/AdminSingleUserPage.vue'),
          },
          {
            name: PAGE_ADMIN_SEGMENTS,
            path: 'segments',
            component: () => import('@/components/admin/segments/AdminSegmentsPage.vue'),
          },
          {
            name: PAGE_ADMIN_SEGMENT_DETAILS,
            path: 'segments/:segmentId',
            component: () => import('@/components/admin/segments/AdminSegmentDetailsPage.vue'),
          },
        ],
      },
      {
        name: 'obsolete-phone-app-version',
        path: 'obsolete-phone-app-version',
        component: () => import('@/components/phoneAppContent/PhoneAppObsoleteVersion.vue'),
      },
    ],
  },
  {
    path: '',
    redirect: {
      name: PAGE_LOGIN,
      params: { lang: i18nSetup.global.locale },
    },
  },
  {
    name: PAGE_NOT_FOUND,
    path: '/:pathMatch(.*)*',
    component: () => import('@/components/generics/errors/errorPage/ErrorPage.vue'),
    props: { errorType: ErrorType.NotFound },
  },
]
